<template>
  <v-dialog
    :value="dlg.visible"
    width="700"
    persistent
  >
    <v-card
      class="white"
      flat
    >
      <v-card-title>
        <span>{{ dlg.title }}</span>
        <v-spacer />
        <v-btn
          icon
          @click="onClose"
        >
          <v-icon>
            {{ btnClose.icon }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card
        class="overflow-y-auto overflow-x-hidden blue-grey lighten-5 pa-6"
        flat
        height="600"
      >
        <div
          class="co-flex-row co-justify-center co-items-center mb-4"
        >
          <v-btn
            :loading="btnMore.loading"
            plain
            :style="loadStates[btnMore.state]"
          >
            {{ loadTexts[btnMore.state] || '查看更多信息' }}
          </v-btn>
        </div>
        <div
          v-for="(item, index) in items"
          :key="index"
          class="co-flex-col"
        >
          <template v-if="item.toId === imForm.userId">
            <message-im-content
              :data="item"
            />
          </template>
          <template v-else>
            <message-im-content
              :data="item"
              back-color="blue"
              fore-color="white"
              reversed
            />
          </template>
        </div>
      </v-card>
      <v-divider />
      <v-card-actions>
        <div class="co-flex-row co-items-center co-w-full my-2">
          <v-text-field
            v-model="msg"
            :label="msgLabel"
            outlined
            hide-details
            class="mr-4"
          />
          <v-btn
            :loading="btnSend.loading"
            color="primary"
            height="56"
            :width="btnSend.width"
            @click="onSend"
          >
            {{ btnSend.text }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import app from '@/api/co.app'
  import api from '@/api/co.api'
  import user from '@/api/co.user'
  import web from '@/api/web/co.web'
  import socket from '@/api/co.socket'

  import dayjs from 'dayjs'

  export default {
    components: {
      MessageImContent: () => import('@/pages/sections/my/MessageImContent.vue'),
    },
    data () {
      return {
        btnClose: {
          icon: 'mdi-close'
        },
        dlg: {
          title: '私信',
          visible: false
        },
        userInfo: {},
        imForm: {},
        imTo: {},
        msg: '',
        msgLabel: '回复',
        btnSend: {
          loading: false,
          width: 90,
          text: '发送'
        },
        btnMore: {
          loading: false,
          state: 0
        },
        params: null,
        items: [],
        loadStates: [
          'color: #2196F3;',
          'color: #2196F3;',
          'color: #a0aec0;',
          'color: #E53E3E;',
          'color: #a0aec0;'
        ],
        loadTexts: [
          '查看更多信息',
          '正在加载中...',
          '全部加载完成',
          '加载失败，重新刷新',
          '暂时没有信息'
        ],
      }
    },
    created () {
      this.userInfo = user.info.get()
      this.imForm = {
        userId: this.userInfo.userId,
        username: this.userInfo.username,
        mobile: this.userInfo.mobile,
        avatar: this.userInfo.avatar || app.pic.avatar_user
      }

      socket.main.bind({
        name: 'dlg_chat',
        trigger: this.receivedMsg
      })
    },
    destroyed () {
      socket.main.unbind('dlg_chat')
    },
    methods: {
      receivedMsg (msg) {
        // console.log('receivedMsg msg: %o', msg)
        this.items.push(msg)
      },
      show (toUser = {}) {
        this.imTo = Object.assign({}, toUser)
        this.dlg.title = this.imTo.username
        this.msgLabel = `回复${this.imTo.username}`
        this.configParams(this.imTo.userId)
        this.loadRecords()
        this.dlg.visible = true
      },
      onClose () {
        this.dlg.visible = false
      },
      configParams (chatUserId) {
        // const me = this
        if (this.params) {
          this.params.data.chatUserId = chatUserId
        } else {
          const executing = function () {
          }

          const executed = function (res) {
            // console.log('items: %o', me.items)
            if (res.status) {
              // const cnt = me.items.length
              // if (cnt > 0) {
              //   const last = me.items[cnt - 1]
              //   me.goTo(last.msgId)
              // }
            }
          }

          this.params = web.imMsg.getParams({
            chatUserId,
            type: 'chat',
            reversed: true,
            caches: this.items,
            executing,
            executed
          })
        }
      },
      loadRecords (reload = true) {
        this.params.reload = reload
        api.httpx.getItems(this.params)
      },
      convertMessageItem (data) {
        const result = Object.assign({}, data)
        result.to = this.imTo
        result.from = this.imForm

        result.hintTime = dayjs(data.createTime).format('M月D日 HH:mm')

        return result
      },
      addMessageToChat (data) {
        this.items.push(data)
      },
      onSend () {
        const me = this
        const content = api.comm.trim(this.msg)
        if (!content) {
          return
        }

        const data = {
          content,
          contentType: web.comm.MessageTypes.CHAT,
          type: web.comm.MessageTypes.CHAT,
          fromId: this.imForm.userId,
          toId: this.imTo.userId
        }

        const executing = function () {
          me.btnSend.loading = true
        }

        const executed = function (res) {
          // console.log({ res })
          if (res.status) {
            const data = res.data
            const dst = me.convertMessageItem(data)
            data.createTime = null
            data.updateTime = null
            data.state = 'ready_send'
            const message = JSON.stringify(data)
            socket.main.send(message).then((_ev) => {
              me.addMessageToChat(dst)
              me.msg = ''
              me.btnSend.loading = false
            }).catch(_err => {
              me.btnSend.loading = false
            })
          } else {
            me.btnSend.loading = false
          }
        }

        web.imMsg.create({
          data,
          executing,
          executed
        })
      }
    }
  }
</script>
